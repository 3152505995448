import React, { useEffect } from "react"
import clsx from "clsx"
import cn from "classnames"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import Img from "./Img"
import { BannerProps } from "./types"
import * as styles from "./banner.module.scss"
import { useObserver } from "../../../hooks/useObserver"
import { handleClickBanner } from "../../../helpers/WatcherDL/utils/handleClickBanner"

const defaultTitle = `
Оплачивай <br/> покупки Халвой
`

const defaultDescription = `
Получай 500 ₽ кэшбэка 
`

export function Banner({
  darkColor,
  buttonText = "Оформить карту",
  title = defaultTitle,
  description = defaultDescription,
  variant = "pkw",
  orderNum,
  anotherFontSize = false,
  variantStyle,
}: BannerProps) {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])
  const otherVariantStyle = variantStyle && styles[variantStyle]
  const isVisible = useObserver(["app", "calculatorCard", "reviewCard", "moreBenefits", "formPkw"])
  return (
    <>
      <section className={clsx(otherVariantStyle)} data-exclude={orderNum}>
        <Container className={cn(styles.container, darkColor && styles.darkColor)}>
          <div
            className={clsx(styles.secondContainer, {
              [styles.notpkwBanner]: variant === "notpkw",
            })}
          >
            <Img alt="bg image" className={styles.img} />

            <div
              className={clsx(
                styles.text,
                { [styles.anotherFontSize]: anotherFontSize },
                styles[variant]
              )}
            >
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button className={styles.btn} onClick={() => handleClickBanner("click_cta")}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={clsx(styles.btnBlock, { [styles.hideMobBlock]: isVisible })}>
        <Container>
          <Button onClick={() => handleClickBanner("sticky")} className={styles.mobBtn}>
            {buttonText}
          </Button>
        </Container>
      </div>
    </>
  )
}
