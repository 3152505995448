// extracted by mini-css-extract-plugin
export var anotherFontSize = "banner-module--anotherFontSize--FAC74";
export var btn = "banner-module--btn--DQzAR";
export var btnBlock = "banner-module--btnBlock--p9vGl";
export var container = "banner-module--container--h3OVV";
export var darkColor = "banner-module--darkColor--88TYL";
export var head = "banner-module--head--VStEO";
export var hideMobBlock = "banner-module--hideMobBlock--m0KyM";
export var img = "banner-module--img--w4h+f";
export var imgFriday = "banner-module--imgFriday--PbfR4";
export var mobBtn = "banner-module--mobBtn--peVdY";
export var notpkwBanner = "banner-module--notpkwBanner--54b80";
export var pkwBonus = "banner-module--pkwBonus--fbAH2";
export var red = "banner-module--red--038ju";
export var secondContainer = "banner-module--secondContainer--cSgE5";
export var subText = "banner-module--subText--nGQVY";
export var subTextFriday = "banner-module--subTextFriday--if-Eh";
export var text = "banner-module--text--m83nH";
export var vkEducation = "banner-module--vkEducation--8xVSw";